// Private Data Provider
import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth = JSON.parse(localStorage.getItem('auth'));

    if (!auth) {
        return Promise.reject();
    }

    const { access_token } = auth;
    options.headers.set('Authorization', `Bearer ${access_token}`);
    return fetchUtils.fetchJson(url, options);
};

const apiBaseURL = (process.env.NODE_ENV === 'production') ? '' : 'http://localhost:8081';
const apiUrl = `${apiBaseURL}/admin/api/v1/operate`;
const dataProvider = simpleRestProvider(apiUrl, httpClient);

const extendDataProvider = {
    ...dataProvider,
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.map(resource => ({ ...resource, id: resource.public_id }) ),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },
    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`)
        .then(({ json }) => {
            /* Hardcode the id for editing the user profile */
            const data = { "data": {
                ...json,
                id: (resource === 'profile') ? 'me' : json.public_id }
            };
            return data;
        });
    },
    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}`;
        return httpClient(url).then(({ json }) => ({
            data: json.map(resource => ({ ...resource, id: resource.public_id }) ),
        }));
    },
    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.map(resource => ({ ...resource, id: resource.public_id }) ),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },
    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            return { data: { ...json, id: json.public_id }};
        });
    },
    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    create: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.public_id }
        }));
    },
    delete: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => (
            { 'data': { ...json, id: json.public_id } }
        ));
    },
    deleteMany: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'DELETE',
            body: JSON.stringify(params.ids),
        }).then(({ json }) => ({ data: json }));
    }
  };

export default extendDataProvider;
