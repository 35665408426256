import * as React from 'react';
import {
    useAuthenticated,
    Title,
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    useLocale,
    DeleteButton
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    content: {
        width: 'calc(100% - 30px)',
        height: '100%',
        border: 'none',
        position: 'absolute'
    },
    form: {
        width: '100%'
    }
});

export const ApiKeysList = (props) => {
    useAuthenticated()
    const classes = useStyles()
    const locale = useLocale()

    const DateFieldForTimestamp = props => {
        const recordWithTimestampAsInteger = {
            [props.source]: parseInt(props.record[props.source], 10)*1000
        };
        return <DateField {...props} record={recordWithTimestampAsInteger} locales={locale} />
    }

    const DateFieldForExpTime = props => {
        const recordWithTimestampAsInteger = {
            [props.source]: (parseInt(props.record[props.ini_field], 10)+(props.record[props.duration_field] * 24 * 60 * 60))*1000
        };
        return <DateField {...props} record={recordWithTimestampAsInteger} locales={locale} />
    }

    return (
        <Card className={classes.root}>
            <Title title="API " />
            <CardContent>
              <List {...props} exporter={false} bulkActionButtons={false} pagination={false} >
                <Datagrid>
                  <TextField label="Name" source="description" />
                  <TextField label="API Key" source="key" />
                  <TextField label="Latitude" source="latitude" />
                  <TextField label="Longitude" source="longitude" />
                  <TextField label="Timezone" source="timezone" />
                  <DateFieldForTimestamp label="Created" source="created_at" showTime />
                  <DateFieldForExpTime label="Expiration Time" source="exp_date" ini_field='renewed_at' duration_field='duration' showTime />
                  <BooleanField label="Active" source="is_active" />
                  <DeleteButton/>
                </Datagrid>
            </List>
            </CardContent>
        </Card>
    )
};

export default ApiKeysList
